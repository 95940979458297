import promiseAPI from '@/APIs/boPromiseAPI'

const state = {
  promises: [],
};

const getters = {

};

const actions = {
  /**
   * Llama al servicio de listado de aplicaciones
   */
  listPromises({ commit }, pageObject) {
    return new Promise((resolve, reject) => {
      let url = '';
      let params = {
        params: {
          page: pageObject.page,
          size: pageObject.size
        }
      };

      promiseAPI.get(url, params)
        .then((response) => {
          commit('SET_PROMISES', response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
  // Update
  updateStatus({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      promiseAPI.put(`/updatepromise/${item.id}/${item.status}`, item )
        .then((response) => {
          commit('UPDATE_PROMISE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  // Update
  generatePromise({ commit }, item) {
    //console.log(JSON.stringify(item.id));
    //console.log(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      promiseAPI.put(`/generatepromise/${item.id}`, item )
        .then((response) => {
          commit('UPDATE_PROMISE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
};

const mutations = {

  SET_PROMISES(state, promises) {
    state.promises = promises
  },
  UPDATE_PROMISE(state, promise) {
    const promiseIndex = state.promises.findIndex((p) => p.id == promise.id)
    Object.assign(state.promises[promiseIndex], promise)
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
