<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div id="data-list-list-view" class="data-list-container">
    <promise-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" v-model="selected" pagination :max-items="itemsPerPage" search :data="promises">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
        </div>
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ promises.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : promises.length }} de {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=5">
              <span>5</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <vs-th sort-key="project">Proyecto</vs-th>
        <vs-th sort-key="bookedUnit">Unidad</vs-th>
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="bookingDate">Fecha</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th>Acciones</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.project.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.bookedUnit }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.client.name }} {{ tr.client.lastName }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.promiseTime | formatDate }}</p>
            </vs-td>
            <vs-td>
              <vs-chip :color="getOrderStatusColor(tr.status)" class="product-order-status">{{translateStatus(tr.status)}}</vs-chip>
              <div v-if="tr.status === 'PAID'">
                <vs-chip :color="getDaysStatusColor(daysDifference(tr.lastUpdate))" class="product-order-status">{{daysDifference(tr.lastUpdate)}} dias de vigencia</vs-chip>                
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div v-if="tr.status != 'PENDING' && tr.status != 'CLIENT_APPROVED'">
              <feather-icon icon="ArchiveIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="retrievePdf(tr.id)" />Ver</div>
              <div>
              <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />Gestionar</div>
            </vs-td>
            <template class="expand-user" slot="expand">
              <div class="vx-row">
                <!--Datos de la promesa -->
                <vs-divider color="primary" icon-pack="feather" position="center">
                  Datos de la Promesa
                </vs-divider>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Nombre:</label>
                  <br>
                  {{tr.project.name}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Unidad:</label>
                  <br>
                  {{tr.bookedUnit}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Unidad:</label>
                  <br>
                  {{tr.bookedUnitPrice| numeral('0, 0.00') }}
                </div>


                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Cuotas:</label>
                  <br>
                  {{tr.quotesNumber}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Estacionamiento:</label>
                  <br>
                  {{tr.bookedAdditional}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Estacionamiento:</label>
                  <br>
                  {{tr.bookedAdditionalPrice| numeral('0, 0.00') }}
                </div>


                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label></label>
                  <br>
                  
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Bodega:</label>
                  <br>
                  {{tr.bookedWarehouse}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Bodega:</label>
                  <br>
                  {{tr.bookedWarehousePrice| numeral('0, 0.00') }}
                </div>


                <!--Datos del Cliente -->
                <vs-divider color="primary" icon-pack="feather" position="center">
                  Datos del Cliente
                </vs-divider>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Nombre:</label>
                  <br>
                  {{tr.client.name}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Segundo Nombre:</label>
                  <br>
                  {{tr.client.secondName}}
                </div>            
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>RUT:</label>
                  <br>
                  {{tr.client.identifier | rutFilter  }}
                </div>  
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Apellido:</label>
                  <br>
                  {{tr.client.lastName}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Apellido Materno:</label>
                  <br>
                  {{tr.client.motherLastName}}
                </div>                     
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Profesión:</label>
                  <br>
                  {{tr.client.profession}}
                </div>                
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Nacionalidad:</label>
                  <br>
                  {{tr.client.nationality}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Estado Civil:</label>
                  <br>
                  {{tr.client.maritalStatus}}
                </div>


                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Email:</label>
                  <br>
                  {{tr.client.contactData.email}}
                </div>                
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Celular:</label>
                  <br>
                  {{tr.client.contactData.cellphoneNumber}}
                </div>                
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Teléfono:</label>
                  <br>
                  {{tr.client.contactData.phoneNumber}}
                </div>  

                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Calle:</label>
                  <br>
                  {{tr.client.contactData.address.street}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Numero:</label>
                  <br>
                  {{tr.client.contactData.address.number}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Departamento:</label>
                  <br>
                  {{tr.client.contactData.address.unitNumber}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Comuna:</label>
                  <br>
                  {{tr.client.contactData.address.city}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Región:</label>
                  <br>
                  {{tr.client.contactData.address.region}}
                </div>


                <vs-divider color="primary" icon-pack="feather" position="center">
                  Datos de Pago
                </vs-divider>

                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Alternativa de Pago:</label>
                  <br>
                  {{translatePaymentAlternative(tr.paymentAlternative)}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Pago de cuotas:</label>
                  <br>
                  {{translatePaymentForm(tr.paymentForm)}}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5" v-show="tr.paymentAlternative != 'INSTALLMENTS'">
                  <label>Pago de cuot&oacute;n:</label>
                  <br>
                  {{translatePaymentForm(tr.paymentFormOne)}}
                </div>

                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Porcentaje de pie:</label>
                  <br>
                  {{tr.footPercentage}}%
                </div>
                <!-- <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Valor de cuotas:</label>
                  <br>
                  {{quotesValue(tr.bookingAmount, tr.amount, tr.quotesNumber, tr.paymentAlternative, tr.footPercentage, tr.uf, tr)| numeral('0, 0.00') }} ({{tr.quotesNumber}})
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5" >
                  <span>
                    <p class="text-l">Cuotas Adicionales:</p>
                    <p class="text-grey text-l"></p>
                      <div >
                        Inicial: UF {{ quotesValue0(tr.bookingAmount,tr.amount, tr.quotesNumber, tr.paymentAlternative, tr.footPercentage, tr.uf, tr)| numeral('0, 0.00') }}
                      </div>
                      <div v-show="tr.paymentAlternative != 'INSTALLMENTS'" >
                        Cuoton: UF {{ quotesValue1(tr.bookingAmount,tr.amount, tr.quotesNumber, tr.paymentAlternative, tr.footPercentage, tr.uf, tr)| numeral('0, 0.00') }}
                      </div>
                  </span>
                </div> -->
                <!-- <div class="vx-col md:w-1/3 w-full mt-5" v-show="tr.paymentAlternative != 'INSTALLMENTS'">
                  <span>
                    <p class="text-l">Porcentajes Adicionales:</p>
                    <p class="text-grey text-l"></p>
                      <div v-show="tr.paymentAlternative == 'BIG_INITIAL_INSTALLMENT_BIG_LAST_INSTALLMENT'" >
                        Inicial {{ tr.initialPercentage }}%
                      </div>
                      <div v-show="tr.paymentAlternative != 'INSTALLMENTS'" >
                        Cuoton: {{ tr.endPercentage }}%
                      </div>
                  </span>
                </div> -->

<!-- 
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Pie:</label>
                  <br>
                  {{tr.amount*((tr.footPercentage)/100)| numeral('0, 0.00') }}
                </div>
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Crédito Bancario:</label>
                  <br>
                  {{tr.amount*((100-tr.footPercentage)/100)| numeral('0, 0.00') }}
                </div> -->
                <div class="vx-col md:w-1/3 w-full mt-5">
                  <label>Monto Total:</label>
                  <br>
                  {{tr.amount| numeral('0, 0.00') }}
                </div>

                <!-- A implementar despliegue de calendario en BO-->
                
                <vs-divider color="primary" icon-pack="feather" position="center">
                  Calendario de Pagos
                </vs-divider>
                
                <vs-table :data="tr.paymentSchedule" class="table-bordered" style="">
                  <thead>
                    <th >Numero</th>
                    <th >Fecha</th>
                    <th >Valor</th>
                    <th >Pagado</th>
                    <th >Estado</th>
                    <th >Transaccion</th>
                    </thead>
                    <tbody>
                      <vs-tr  :key="index" v-for="(tp, index) in tr.paymentSchedule">
                        <vs-td width="200px">
                          <p class="product-category"> {{ tp.installmentNumber }}</p>
                        </vs-td>
                        <vs-td width="200px"> 
                          <p class="product-category">{{ tp.scheduledPaymentDate | formatDate }}</p>
                        </vs-td>
                        <vs-td width="200px">
                          <p class="product-category">UF {{ tp.paymentAmount   }}</p>
                        </vs-td>
                        <vs-td v-if="tp.paymentMethodData" width="200px">
                          <p class="product-category"> {{ tp.paymentAmount *tp.paymentMethodData.meta.uf |numeral('$0, 0')}}</p>
                        </vs-td>
                        <vs-td v-else width="200px">
                          <p class="product-category">{{ tp.amount |numeral('$0, 0')  }}</p>
                        </vs-td>
                        <vs-td :color="getPaymentStatusColor(tp.status)"  width="200px" class="product-order-status">
                          <vs-chip :color="getPaymentStatusColor(tp.status)" class="product-order-status">
                                  <p class="product-category">{{ translatePaymentStatus(tp.status) }}</p>
                          </vs-chip>
                        </vs-td>
                        <vs-td v-if="tp.paymentMethodData" width="200px">
                          <p v-if="tp.paymentMethodData.meta.tbkTransactionId!=0" class="product-category"> tbk: {{ tp.paymentMethodData.meta.tbkTransactionId  }}</p>
                          <p v-if="tp.paymentMethodData.meta.buyOrder" class="product-category"> ord: {{ tp.paymentMethodData.meta.buyOrder  }}</p>
                        </vs-td>
                        <vs-td v-else width="200px">
                          <p class="product-category"></p>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                </vs-table>

                <div class="vx-col md:w-1/3 w-full mt-5">
                </div>
              </div>
            </template>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import EdocService from '@/services/EdocService';
import PromiseViewSidebar from '@/views/ui-elements/data-list/PromiseViewSidebar.vue'
import modulePromise from "@/store/promise/modulePromise.js"
import Vue from 'vue';
import { rutFilter } from 'vue-dni';

import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, { locale: 'es' });

Vue.filter('rutFilter', rutFilter);
export default {
  components: {
    PromiseViewSidebar
  },
  data() {
    return {
      selected: [],
      // products: [],
      itemsPerPage: 5,
      isMounted: false,

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      transactionsArray:[],
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    promises() {
      return this.$store.state.dataListPromise.promises
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.promises.length
    }
  },
  methods: {
    quotesValue(bookingAmount, amount, quotesNumber, paymentAlternative, footPercentage, uf, promise){
      var initialPercentage = (0+promise.endPercentage)/100;
      var endPercentage = (0+promise.endPercentage)/100;
      if (paymentAlternative == 'INSTALLMENTS') {
      this.$log.debug('quotesValue INSTALLMENTS');
        var footPercentage100 = footPercentage/100;
        var bookingAmountUf = bookingAmount/uf;

        var quotesValue = (amount * (footPercentage100-(((bookingAmountUf*100)/amount)/100))) / quotesNumber;
        return quotesValue;
      }
      if (paymentAlternative == 'INSTALLMENTS_BIG_LAST_INSTALLMENT') {
      this.$log.debug('quotesValue INSTALLMENTS_BIG_LAST_INSTALLMENT');
        footPercentage100 = footPercentage/100;
        var footPercentageQ = footPercentage100-endPercentage;
        this.$log.debug('footPercentage100  :  - ', footPercentage100);
        this.$log.debug('footPercentageQ  :  - ', footPercentageQ);
        bookingAmountUf = bookingAmount/uf;

        quotesValue = ((amount * (footPercentageQ-(((bookingAmountUf*100)/amount)/100)))/(quotesNumber-1)*100)/100;
        return quotesValue;
      }
      //agregar condiciones
      if (paymentAlternative == 'BIG_INITIAL_INSTALLMENT_BIG_LAST_INSTALLMENT') {
      this.$log.debug('quotesValue BIG_INITIAL_INSTALLMENT_BIG_LAST_INSTALLMENT');
        footPercentage100 = footPercentage/100;
        footPercentageQ = footPercentage100-endPercentage-initialPercentage;
        bookingAmountUf = bookingAmount/uf;

        quotesValue = ((amount * (footPercentageQ-(((bookingAmountUf*100)/amount)/100)))/(quotesNumber-1)*100)/100;
        return quotesValue;
      }
    },
    quotesValue0(bookingAmount, amount, quotes, paymentAlternative, footPercentage, uf, promise){

      var initialPercentage = (0+promise.initialPercentage)/100;
      if (paymentAlternative == 'BIG_INITIAL_INSTALLMENT_BIG_LAST_INSTALLMENT') {

        var quoteValue0 = ( amount* initialPercentage*100)/100;
        return quoteValue0;
      }
      return 0;
      
    },
    quotesValue1(bookingAmount, amount, quotes, paymentAlternative, footPercentage, uf, promise){

      var endPercentage = (0+promise.endPercentage)/100;
      if (paymentAlternative == 'INSTALLMENTS_BIG_LAST_INSTALLMENT' || paymentAlternative == 'BIG_INITIAL_INSTALLMENT_BIG_LAST_INSTALLMENT') {

        var quoteValue1 = ( amount* endPercentage*100)/100;
        return quoteValue1;
      }
      return 0;
      
    },
    daysDifference (date) {
      var date1 = new Date(date);  
      var date2 = new Date();
      // The number of milliseconds in one day
      var ONEDAY = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      var date1_ms = date1.getTime();
      var date2_ms = date2.getTime();
      // Calculate the difference in milliseconds
      var difference_ms = Math.abs(date1_ms - date2_ms);
      // Convert back to days and return
      var value = 15-Math.round(difference_ms/ONEDAY);
      if (value < 0) {
        value = 0;
      }
      return value;
    },
    getOrderStatusColor(status) {
      if (status == 'PENDING') return "warning"
      if (status == 'CLIENT_APPROVED') return "primary"
      if (status == 'GENERATED') return "success"
      if (status == 'FINISHED') return "success"
      if (status == 'LOCKED') return "primary"
      if (status == 'PROCESSING') return "success"
      return "primary"
    },
    translatePaymentStatus (status) {
      if (status == 'PENDING') return 'Pendiente';
      if (status == 'IN_PROCESS') return 'En proceso';
      if (status == 'PAID') return 'Pagado';
      if (status == 'REJECTED') return 'Rechazado';
      return 'Indeterminado';
    },
    translateStatus(status) {
      if (status == 'PENDING') return "Pendiente"
      if (status == 'CLIENT_APPROVED') return "Aprobada por Cliente"
      if (status == 'GENERATED') return "Generada"
      if (status == 'FINISHED') return "Terminada"
      if (status == 'LOCKED') return "Bloqueada"
      if (status == 'PROCESSING') return "En curso"
      return "Indeterminado"
    },
    translatePaymentForm(paymentForm) {
      if (paymentForm == 'NA') return "NA"
      if (paymentForm == 'CHECK') return "Cheque"
      if (paymentForm == 'TC') return "Tarjeta de Crédito"
      if (paymentForm == 'ONECLICK') return "OneClick"
      return "Indeterminado"
    },
    getPaymentStatusColor (status) {
      if (status == 'PENDING') return 'warning';
      if (status == 'IN_PROCESS') return 'primary';
      if (status == 'PAID') return 'success';
      if (status == 'REJECTED') return 'danger';
      return 'primary';
    },
    translatePaymentAlternative(paymentAlternative) {
      if (paymentAlternative == 'INSTALLMENTS') return "Cuotas Iguales"
      if (paymentAlternative == 'INSTALLMENTS_BIG_LAST_INSTALLMENT') return "Cuot&oacute;n mas cuotas"
      return "Indeterminado"
    },
    showPromise(promiseId) {
      localStorage.setItem("promiseId", promiseId);
      this.$router.push('/clientPanel/promiseview/');
    },
    checkPending(bookingStatus) {
      if (bookingStatus=="PENDING" || bookingStatus=="APPROVED") {
        return true;
      }
      return false;
    },
    retrievePdf(promiseId) {
      var code = process.env.VUE_APP_EDOC_API_ROOT + EdocService.promisePdf(promiseId);
      var win = window.open(code, '_blank');
      win.focus();
    },
    retrieveBookingDocumentPdf(bookingId) {
      var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_CPA_API + EdocService.bookingDocumentPdf(bookingId);
      var win = window.open(code, '_blank');
      win.focus();
    },
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    deleteData(id) {
      this.$store.dispatch("dataListBooking/removeItem", id)//.catch(err => { console.error(err) })
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      //console.log(data);
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    if (!modulePromise.isRegistered) {
      this.$store.registerModule('dataListPromise', modulePromise)
      modulePromise.isRegistered = true
    }
    let pageObject = {
      page: 0,
      size: 100
    };

    this.$store.dispatch("dataListPromise/listPromises", pageObject)
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;
        .vs-table--search-input {
          width: 100%;
        }
      }
    }
    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }
    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;
        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      >span {
        display: flex;
        flex-grow: 1;
      }
      .vs-table--search {
        padding-top: 0;
        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;
          &+i {
            left: 1rem;
          }
          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;
      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;
        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }
    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
