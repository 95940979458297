import promiseAPI from '@/APIs/boPromiseAPI'
import edocAPI from '@/APIs/edocAPI'

export default {

  uploadFile: function (promiseId, formData) {

    const url = '/insurancedocument/'+promiseId;
    //console.log(formData);

    let headers = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }

    return promiseAPI.post(url, formData, headers)
        .then(function(){
          //console.log('SUCCESS!!');
        })
        .catch(function(){
          //console.log('FAILURE!!');
        });
  },

  overrideFile: function (promiseId, formData) {

    const url = '/retrieve/promiseoverride/'+promiseId;
    //console.log(formData);

    let headers = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }

    return edocAPI.post(url, formData, headers)
        .then(function(){
          //console.log('SUCCESS!!');
        })
        .catch(function(){
          //console.log('FAILURE!!');
        });
  },

  notificationPromise: function (promiseId) {
    return new Promise((resolve, reject) => {
      promiseAPI.put(`/notify/${promiseId}` )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })

  }


};
