<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? "AGREGAR" : "EDITAR" }} PROMESA</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Unidad -->
        <vs-input label="Unidad" v-model="bookedUnit" class="mt-5 w-full" name="item-name" disabled />
        <!-- Additional -->
        <vs-input label="Valor" v-model="bookedUnitPrice" class="mt-5 w-full" name="item-name" disabled />
        <!-- Warehouse -->
        <vs-input label="Bodega" v-model="bookedWarehouse" class="mt-5 w-full" name="item-name" disabled />

        <!-- Warehouse -->
        <vs-select autocomplete v-model="status" label="Estado" class="mt-5 w-full" name="item-category" >
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in status_choices" />
        </vs-select>

        <vs-divider color="primary" icon-pack="feather" position="center">
          Datos de Pago
        </vs-divider>

        <div class="vx-col md:w-1/3 w-full mt-5">
          <label>Alternativa de Pago:</label>
          <br>
          {{translatePaymentAlternative(paymentAlternative)}}
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5">
          <label>Pago de cuotas:</label>
          <br>
          {{translatePaymentForm(paymentForm)}}
        </div>
        <div class="vx-col md:w-1/3 w-full mt-5">
          <label>Pago de cuoton:</label>
          <br>
          {{translatePaymentForm(paymentFormOne)}}
        </div>


        <vs-divider color="primary" icon-pack="feather" position="center">
          Seguro de Venta en Verde
        </vs-divider>
        <div class="vx-col w-full mt-5"> 
          <vs-button color="primary" type="border" icon="file_copy" @click="retrieveInsuranceDocumentPdf(id)">
            Ver
          </vs-button>
          <label for="file">Subir</label>
          <form id="uploadForm" enctype="multipart/form-data">
            <input type="file" id="fileUpload" name="fileUpload" v-on:change="uploadFile(this, id)">
          </form>
        </div>


        <vs-divider color="primary" icon-pack="feather" position="center">
          Promesa
        </vs-divider>
        <vs-button color="primary" type="border" icon="file_copy" @click="retrievePromiseDocumentPdf(id)">
          Ver
        </vs-button>
        <label for="file">Subir</label>
        <form id="uploadForm" enctype="multipart/form-data">
          <input type="file" id="fileOverride" name="fileOverride" v-on:change="overridePromiseFile(this, id)">
        </form>


      </div>
    </VuePerfectScrollbar>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData()">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>
<script>
import promiseService from '@/services/PromiseService';
import EdocService from '@/services/EdocService';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    }
  },
  watch: {
    isSidebarActive(val) {
      //console.log(val);
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, 
          projectId, 
          bookedUnit, 
          promiseTime, 
          status, 
          bookedUnitPrice,
          bookedWarehouse,
          paymentForm,
          paymentFormOne,
          paymentAlternative } = JSON.parse(JSON.stringify(this.data))
        this.id = id
        this.projectId = projectId
        this.bookedUnit = bookedUnit
        this.promiseTime = promiseTime
        this.status = status
        this.bookedUnitPrice = bookedUnitPrice
        this.bookedWarehouse = bookedWarehouse
        this.paymentForm = paymentForm
        this.paymentFormOne = paymentFormOne
        this.paymentAlternative = paymentAlternative
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      id: null,
      name: "",
      img: null,


      type: "",
      client: { contactData : {} },
      projectId: "",
      bookedUnit: null,
      status: "",
      promiseTime: "",
      bookedUnitPrice: null,
      bookedWarehouse: null,
      currencyIsoCode: "",
      paymentForm: "",
      paymentFormOne: "",
      paymentAlternative: "",


      Price: 0,

      status_choices: [
        { text: 'Pendiente', value: 'PENDING' },
        { text: 'Aprobada por cliente', value: 'CLIENT_APPROVED' },
        { text: 'Generada', value: 'GENERATED' },
        { text: 'Terminada', value: 'FINISHED' },
        { text: 'Bloqueada', value: 'LOCKED' },
        { text: 'En curso', value: 'PROCESSING' }
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
          //this.$validator.reset()
          //this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.dataName;
    }
  },
  methods: {
    retrieveInsuranceDocumentPdf(bookingId) {
      var code = process.env.VUE_APP_API_ROOT + process.env.VUE_APP_BKNG_API + EdocService.insuranceDocumentPdf(bookingId);
      var win = window.open(code, '_blank');
      win.focus();
    },
    uploadFile: function (file, promiseId) {
      let formData = new FormData();
      formData.append('file', document.getElementById("fileUpload").files[0]);
      //const file = event.target.files[0];
      promiseService.uploadFile(promiseId, formData)
        .then(response => {
          if (response) {
            alert("Documento cargado con exito");
          }
        })
        .catch(error => {
          this.$log.error('Error calling clientService.listClient :  - ', error);
        });
    },
    retrievePromiseDocumentPdf(bookingId) {
      var code = process.env.VUE_APP_EDOC_API_ROOT + EdocService.promisePdf(bookingId);
      var win = window.open(code, '_blank');
      win.focus();
    },
    overridePromiseFile: function (file, promiseId) {
      let formData = new FormData();
      formData.append('file', document.getElementById("fileOverride").files[0]);
      //const file = event.target.files[0];
      promiseService.overrideFile(promiseId, formData)
        .then(response => {
          if (response) {
            alert("Documento cargado con exito");
          }
        })
        .catch(error => {
          this.$log.error('Error calling clientService.overrideFile :  - ', error);
        });
    },
    promiseNotification(promiseId) {
      promiseService.notificationPromise(promiseId)
        .then(response => {
          if (response) {
            //alert("Documento cargado con exito");
          }
        })
        .catch(error => {
          this.$log.error('Error calling clientService.notificationPromise :  - ', error);
        });
    },
    translatePaymentForm(paymentForm) {
      if (paymentForm == 'NA') return "NA"
      if (paymentForm == 'CHECK') return "Cheque"
      if (paymentForm == 'TC') return "Tarjeta de Crédito"
      if (paymentForm == 'ONECLICK') return "OneClick"
      return "Indeterminado"
    },
    translatePaymentAlternative(paymentAlternative) {
      if (paymentAlternative == 'INSTALLMENTS') return "Cuotas Iguales"
      if (paymentAlternative == 'INSTALLMENTS_BIG_LAST_INSTALLMENT') return "Cuoton mas cuotas"
      return "Indeterminado"
    },
    initValues() {
      if (this.data.id) return
      this.Id = null
      this.Name = ""
      this.Category = null
      this.Order_status = "pending"
      this.Price = 0
      this.Img = null
    },
    submitData() {
      //console.log("submitData");
      //this.$validator.validateAll().then(result => {
      //if (this.dataId) {
      const obj = {
        id: this.id,
        status: this.status,
      }
      //console.log(this.id);
      //console.log(obj);

      if (this.id !== null) {
        //console.log("update run");
        if (this.status === "GENERATED") {
          this.$store.dispatch("dataListPromise/generatePromise", obj)//.catch(err => { /*console.error(err)*/ })
        } else {
          this.$store.dispatch("dataListPromise/updateStatus", obj)//.catch(err => { /*console.error(err)*/ })          
        }

      } else {
        //console.log("new run");
        this.$store.dispatch("dataListPromise/newItem", obj)//.catch(err => { /*console.error(err)*/ })

      }
      this.isSidebarActiveLocal = false;

      //this.$emit('closeSidebar')
      //this.initValues()
      //}
      //})
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.Img = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  components: {
    VuePerfectScrollbar,
  }
}

</script>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}

</style>
